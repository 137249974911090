
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('../landingpage/index.scss');

import $ from 'jquery';
window.$ = window.jQuery = $;
require('@fancyapps/fancybox');
require('./ask');
import { scrollAnchor } from './utils/scroll';
require('bootstrap');
import 'slick-carousel';

$(document).on('click', 'a', scrollAnchor);

$(function () {

  $('.testi-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: $('.next-slide'),
    prevArrow: null,
    fade:true,
  });
});