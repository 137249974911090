// Import the plugins
const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')
const Webcam = require('@uppy/webcam')
const CzechLocale = require('@uppy/locales/lib/cs_CZ')
// const GermanLocale = require('@uppy/locales/lib/de_DE')

// And their styles (for UI plugins)
require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/webcam/dist/style.css')

const uppy = Uppy({
    locale: CzechLocale,
    autoProceed: true,
    restrictions: {
      allowedFileTypes: ['image/*', 'video/*']
    },
    meta: {
      '_token': $('[name="_token"]').val()
    }
  })
  .use(Dashboard, {
    inline: true,
    height: 200,
    target: '#photodropdown'
  })
  .use(Webcam, {
    target: Dashboard,
    facingMode: 'environment',
    modes: ['picture']
  })
  .use(XHRUpload, { endpoint: document.getElementById('photodropdown').dataset.endpoint })

uppy.on('complete', (result) => {
  console.log('Upload complete! We’ve uploaded these files:', result.successful)
})

const $beds = $('.children-beds .bed');
$beds.click(function (e) {
  if (!$(e.target).is('.zoom') && !$(e.target).parent().is('.zoom')) {
    $beds.removeClass('selected');
    $(this).addClass('selected');
    let button = $(this).find('button.zoom');
    const value = button.length ? button.data('caption').replace('<br>', ' - ') : $(this).text();
    $('#askform_bed_choice').val(value);
  }
})
